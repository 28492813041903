(function ($) {
  Drupal.behaviors.emailSignupPopupV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      this.initPopupSignup(context);
    },
    initPopupSignup: function (context) {
      var $emailSignupBlock = $('.js-signup-block', context);;
      var $emailContainerNodes = $('.js-email-signup', $emailSignupBlock);

      if ($emailContainerNodes.length === 0) {
        return null;
      }

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailContent = $('.js-site-email-signup-email-content', $emailContainerNode);
        var $gdprLink = $('.js-site-email-signup-gdpr-link', $emailContainerNode);
        var $subscribeCta = $('.js-site-email-signup-cta', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup-success', $emailContainerNode);
        var $emailError = $('.js-email-signup-error', $emailContainerNode);
        var $emailInput = $('.js-pc-email-address', $emailContainerNode);
        var $errorMessages = $('.js-email-signup-errors', $emailContainerNode);
        var $closeSignupBlock = $('.js-close-signup-block', $emailSignupBlock);
        var $subscribeEnabled = $('.js-subscribe-cta', $emailSignupBlock);
        var params = {};

        function setSignupCookie() {
          var date = new Date();

          date.setTime(date.getTime() + (24 * 60 * 60 + 1) * 1000);
          document.cookie = 'subscribe_popup=1; path=/; expires=' + date.toGMTString() + ';';
        }

        if (!generic.cookie('subscribe_popup') ) {
          $emailSignupBlock.removeClass('hidden').addClass('moveup');
        }

        $closeSignupBlock.once().on('click', function () {
          $emailSignupBlock.addClass('hidden');
          setSignupCookie();
        });

        $emailInput.once().on('focusout', function () {
          if ($subscribeEnabled.hasClass('button--disabled')) {
            $subscribeEnabled.removeAttr('disabled').removeClass('button--disabled');
          }
        });

        $emailForm.once('js-email-signup').on('submit',function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailInput.removeClass('error');

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          if ($.cookie('csrftoken')) {
            params._TOKEN = $.cookie('csrftoken');
          }

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function () {
              $emailContent.add($gdprLink).add($subscribeCta).addClass('hidden');
              $emailSuccess.removeClass('hidden');
              setSignupCookie();
            },
            onFailure: function (result) {
              var errorObjectsArray = result.getMessages();

              $emailError.removeClass('hidden');
              generic.showErrors(errorObjectsArray, $errorMessages, null);
              $subscribeEnabled.attr('disabled', 'disabled').addClass('button--disabled');
            }
          });
        });
      });
    },
  };
})(jQuery);
